import { ProgressBar } from "src/ecommerce/components/partials/ProgressBar";

const steps = [
  {
    number: "1",
    text: "Wyszukaj produkt",
    link: "/rent",
  },
  {
    number: "2",
    text: "Wybierz okres",
    link: "/rent/[orderCode]/period",
  },
  {
    number: "3",
    text: "Uzupełnij dane",
    link: "/rent/[orderCode]/personal-data",
  },
  {
    number: "4",
    text: "Weryfikacja tożsamości",
    link: "/rent/[orderCode]/personal-data-details",
  },
  {
    number: "5",
    text: "Podsumowanie",
    link: "/rent/[orderCode]/summary",
  },
];

export function ProgressBarRent(props) {
  return <ProgressBar steps={steps} {...props} />;
}
