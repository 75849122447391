export const create = async (data) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + "/api/rest/public/orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const update = async (code, data) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const findOneByCode = async (code) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}`);

  return response.json();
};

export const findShippingMethodsByCode = async (code) => {
  const response = await fetch(
    process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}/shipping-methods`,
  );

  return response.json();
};

export const clearVerification = async (code) => {
  const response = await fetch(
    process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/order/${code}/clear-verification`,
    {
      method: "POST",
    },
  );

  return response;
};

export const findPayment = async (code) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/payments/${code}`);

  return response.json();
};
